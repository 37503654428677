<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-shops' }">{{
            $t("MENU.ITEM.SHOPS.SHOPS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['shops-destroy']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingShop"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <div :transition="false" :reverse-transition="false">
          <b-form>
            <ShopForm ref="Form" :form="form" />
            <v-overlay :value="!firstLoader && isLoadingShop">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </b-form>
        </div>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['shops-destroy']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingShop"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_SHOP,
  UPDATE_SHOP,
  DELETE_SHOP
} from "@/modules/shop/store/shop.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";
import ShopForm from "../components/ShopForm.vue";

export default {
  components: {
    KTCard,
    SaveButtonDropdown,
    ShopForm
  },
  data() {
    return {
      errors: {},
      firstLoader: true,
      form: {}
    };
  },
  computed: {
    ...mapGetters(["isLoadingShop"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Магазини", route: { name: "list-shops" } },
      { title: "Редактиране на магазин" }
    ]);
    this.getShop();
  },
  methods: {
    getShop() {
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_SHOP, id)
        .then(data => {
          this.$nextTick(() => {
            this.form = data.data;
            this.firstLoader = false;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            this.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-shops" });
          }
        });
    },
    prepareFormData() {
      const { image, ...rest } = this.form;
      let formData = new FormData();
      if (typeof image !== "string") {
        formData.append("image", image);
      }

      formData.append("payload", JSON.stringify(rest));
      return formData;
    },
    onSave(next) {
      this.$refs.Form.$v.form.$touch();
      if (this.$refs.Form.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Моля попълнете всички задължителни полета"
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_SHOP, {
          id: this.form.id,
          payload: this.prepareFormData()
        })
        .then(data => {
          this.errors = data.data.errors;
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          if (next == "new") {
            this.$router.push({ name: "add-shop" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-shops" });
          }
        })
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    },
    onDelete() {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(DELETE_SHOP, this.form.id)
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.$router.push({ name: "list-shops" });
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response?.data?.message
              });
            });
        })
        .catch(() => {});
    }
  }
};
</script>
