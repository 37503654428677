<template>
  <div>
    <b-form-row>
      <b-col md="6">
        <b-form-group
          v-bind:id="'name-input'"
          v-bind:label="$t('FORM_LABELS.NAME') + '*'"
          v-bind:label-for="'name-input'"
        >
          <div class="input-group">
            <FormInput
              id="name-input"
              type="text"
              name="name"
              :validateState="validateState('name')"
              v-model="form.name"
            >
            </FormInput>
          </div>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          v-bind:id="'categoryIds-input'"
          v-bind:label="$t('FORM_LABELS.CATEGORIES') + '*'"
          v-bind:label-for="'categoryIds-input'"
        >
          <FormMultiSelect
            id="categories-input"
            :placeholder="$t('FORM_LABELS.CATEGORIES')"
            type="select"
            name="categories"
            :options="categories"
            v-model="form.categories"
          ></FormMultiSelect>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          v-bind:id="'url-input'"
          v-bind:label="$t('FORM_LABELS.URL') + '*'"
          v-bind:label-for="'url-input'"
        >
          <div class="input-group">
            <FormInput
              id="url-input"
              name="url"
              :validateState="validateState('url')"
              v-model="form.url"
            >
            </FormInput>
          </div>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          v-bind:id="'description-input'"
          v-bind:label="$t('FORM_LABELS.DESCRIPTION') + '*'"
          v-bind:label-for="'description-input'"
        >
          <FormTextarea
            id="description-input"
            name="description"
            v-model="form.description"
          >
          </FormTextarea>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group
          v-bind:id="'image-input'"
          v-bind:label="$t('FORM_LABELS.IMAGE') + '*'"
          v-bind:label-for="'image-input'"
        >
          <div class="input-group">
            <FormFile
              name="image"
              image
              :validateState="validateState('image')"
              v-model="form.image"
            >
            </FormFile>
          </div>
        </b-form-group>
      </b-col>

      <b-col :md="12">
        <b-label class="ml-0 pl-0">
          {{ $t("FORM_LABELS.IS_NEW") }}
          <FormSwitch
            id="isNew"
            type="checkbox"
            name="isNew"
            v-model="form.isNew"
          ></FormSwitch>
        </b-label>
      </b-col>

      <b-col :md="12">
        <b-label class="ml-0 pl-0">
          {{ $t("FORM_LABELS.ACTIVE") }}
          <FormSwitch
            id="active"
            type="checkbox"
            name="active"
            v-model="form.active"
          ></FormSwitch>
        </b-label>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch";
import FormMultiSelect from "@/view/content/forms/components/FormMultiSelect.vue";
import FormTextarea from "@/view/content/forms/components/FormTextarea.vue";
import FormFile from "@/view/content/forms/components/FormFile.vue";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";
import { FETCH_CATEGORIES } from "@/modules/category/store/category.module";

export default {
  name: "ShopForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormInput,
    FormSwitch,
    FormMultiSelect,
    FormTextarea,
    FormFile
  },
  props: {
    form: Object
  },
  validations() {
    const tmpValidationObject = {
      form: {
        name: this.setItemValidations({
          required: true
        }),
        url: this.setItemValidations({
          required: true
        }),
        image: this.setItemValidations({
          required: true
        })
      }
    };

    return tmpValidationObject;
  },
  data() {
    return {
      categories: []
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.$store
        .dispatch(
          FETCH_CATEGORIES,
          this.$url.transformParams({ onlySelectValues: true })
        )
        .then(({ data }) => {
          this.categories = data;
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
